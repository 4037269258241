<template>
  <div class="phase1">
    <div>
      <section>
        <div class="bsat__title">{{ $t("title") }}</div>
        <div class="bsat__paragraph">{{ $t("paragraph1") }}</div>
      </section>

      <section>
        <div class="bsat__subtitle">{{ $t("subtitle") }}</div>
        <div class="bsat__paragraph">{{ $t("paragraph2") }}</div>
      </section>
    </div>

    <!-- ***************** STEPS ****************** -->

    <div id="steps" class="steps">
      <div class="bsat__subtitle">{{ $t("stepsTitle") }}</div>
      <v-stepper class="elevation-0 bg-off_white" v-model="steps">
        <!-- ************* STEPPER HEADER **************** -->
        <v-stepper-header
          :style="{ maxWidth: $vuetify.breakpoint.smAndUp ? '50%' : '' }"
        >
          <v-stepper-step @click="steps = 1" step="1"> </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step @click="steps = 2" step="2"> </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step @click="steps = 3" step="3"> </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step @click="steps = 4" step="4"> </v-stepper-step>
        </v-stepper-header>

        <!-- ***************** STEPPER CONTENT ***********************-->
        <v-stepper-items>
          <v-stepper-content
            v-for="(step, i) in phase1"
            :key="i"
            :step="i + 1"
            class="steps__content elevation-2 px-0"
          >
            <keep-alive>
              <component :is="step.component"></component>
            </keep-alive>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>

    <Divider></Divider>

    <div class="bsat__subtitle">{{ $t("power4BioExperience") }}</div>

    <!--************************* TABS **************************** -->
    <div class="phase1__tabs">
      <v-tabs
        v-model="tab"
        class="bsat__tabs"
        background-color="transparent"
        slider-color="dark"
        color="dark"
        show-arrows
        center-active
      >
        <v-tab
          v-for="(item, i) in footerTabs"
          :key="i"
          class="mr-4 mr-sm-8"
          :style="
            $vuetify.breakpoint.xsOnly ? 'min-width: 90px' : 'min-width: 160px'
          "
        >
          {{ $t(item.tab) }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="py-4 px-2">
        <!-- ********** REAL EXAMPLES TAB ******* -->
        <v-tab-item>
          <div class="bg-off_white">
            <div class="bsat__subtitle">{{ $t("realExamples.cardTitle") }}</div>
            <div class="bsat__paragraph">
              {{ $t("realExamples.paragraph") }}
            </div>
            <a :href="swotAnalysisFile" target="_blank" download>
              <v-btn
                class="bsat__btn"
                color="dark"
                dark
                depressed
                width="150"
                height="26"
                >{{ $t("download") }}</v-btn
              >
            </a>
          </div>
        </v-tab-item>
        <!-- ********** NOTES TAB ******* -->
        <v-tab-item>
          <div class="bg-off_white">
            <div class="bsat__subtitle">{{ $t("notes.title") }}</div>
            <div class="bsat__paragraph">{{ $t("notes.paragraph1") }}</div>
            <div>
              <i18n path="notes.fao.text" tag="div" class="bsat__paragraph">
                <template #title>
                  <a :href="faoLink" target="_blank">
                    <strong>{{ $t("notes.fao.title") }}</strong>
                  </a>
                </template>
              </i18n>
            </div>
            <div>
              <i18n path="notes.scar1.text" tag="div" class="bsat__paragraph">
                <template #title>
                  <a :href="scarLink" target="_blank">
                    <strong>{{ $t("notes.scar1.title") }}</strong>
                  </a>
                </template>
              </i18n>
              <div class="bsat__paragraph">
                {{ $t("notes.scar2") }}
              </div>
            </div>

            <div>
              <i18n path="notes.eu.text" tag="div" class="bsat__paragraph">
                <template #title>
                  <a :href="euCommisionLink" target="_blank">
                    <strong>{{ $t("notes.eu.title") }}</strong>
                  </a>
                </template>
              </i18n>
            </div>
            <div class="bsat__subtitle">{{ $t("notes.title2") }}</div>
            <div>
              <i18n path="notes.ukraine.text" tag="div" class="bsat__paragraph">
                <template #title>
                  <a href="#" target="_blank">
                    <strong>{{ $t("notes.ukraine.title") }}</strong>
                  </a>
                </template>
              </i18n>
            </div>
            <div>
              <i18n path="notes.poland.text" tag="div" class="bsat__paragraph">
                <template #title>
                  <a href="#" target="_blank">
                    <strong>{{ $t("notes.poland.title") }}</strong>
                  </a>
                </template>
              </i18n>
            </div>
            <div>
              <i18n path="notes.it.text" tag="div" class="bsat__paragraph">
                <template #title>
                  <a href="#" target="_blank">
                    <strong>{{ $t("notes.it.title") }}</strong>
                  </a>
                </template>
              </i18n>
            </div>

            <div v-if="route == 'Update'">
              <i18n path="notes.baviera.text" tag="div" class="bsat__paragraph">
                <template #title>
                  <a href="#" target="_blank">
                    <strong>{{ $t("notes.baviera.title") }}</strong>
                  </a>
                </template>
              </i18n>
              <i18n
                path="notes.baviera.text2"
                tag="div"
                class="bsat__paragraph"
              >
              </i18n>
            </div>
          </div>
        </v-tab-item>

        <!-- ********** OPINIONS TAB ******* -->
        <!-- <v-tab-item>
          <v-card flat color="off_white">
            <v-card-text class="px-0">
              <v-list dense class="bg-off_white">
                <v-list-item
                  dense
                  class="text-default"
                  v-for="(item, i) in currentOpinions"
                  :key="i"
                >
                  <v-list-item-avatar size="40">
                    <v-img src="@/assets/icons/profile.png"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.subtitle
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-row>
                <v-spacer></v-spacer>
                <v-pagination
                  class="pagination"
                  color="dark"
                  v-model="page"
                  :length="2"
                ></v-pagination>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item> -->
      </v-tabs-items>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "notes": {
      "eu": {
        "text": "{title} Comprises those parts of the economy that use renewable biological resources from land and sea – such as crops, forests, fish, animals and micro-organisms – to produce food, materials and energy.",
        "title": "European Commission:"
      },
      "it":{
        "text": "Italy: Initial assessment of regional bioeconomy status quo and potential challenged regional administrations in collecting different information from different sources, fostering the awareness about the importance to create a dedicated, cross-sectoral working group to better evaluate all the different aspects of the bioeconomy value chains on the territory. KPIs performance indicators to evaluate regional bioeconomy where a useful tool to have over hand all the different aspects to be considered when thinking about a regional bioeconomy strategy, and helped also in identify the different regional stakeholders that need to be involved at different level.",
        "title": "Italy:"
      },
      "fao": {
        "text": "{title} Bioeconomy comprises the parts of the economy that use renewable biological re-sources (plants, animals, micro-organisms, etc.) to replace fossil fuels, and produce food, animal feed, and other biobased products. Its cross-cutting nature offers a unique opportunity to comprehensively address  interconnected societal challenges such as food security, natural resource scarcity, fossil-resource dependence and climate change, while achieving sustainable economic development.",
        "title": "FAO:"
      },
      "ukraine": {
          "text": "Lviv: In the Lviv region of Ukraine we experienced lack of awareness regarding bioeconomy importance on the stage of the RBH establishment and regional stakeholders involving. Then, we decided to increase the number of planning by POWER4BIO meetings and besides several meetings of the Lviv RBH we used all regional possibilities in this regard for our idea promoting organizing the following events: Special Week “Sustainable Constructions technologies”; Round table “Topical issues of the development for the wood processing industry of Ukraine”; Round table “Problems and prospects of the technological education in the field of furniture and woodworking”; Bioeconomy – smart specialization of the Lviv region (discussion with EU expert Jurgita Petrauskiene); Bioeconomy of the Lviv region - Workshop within the framework of “Lviv Industrial day”; Workshop with the representatives of the Forest Council “About the current situation in forest sector”; Round table “Problems of the quality products in woodworking and furniture industries”. It allows us essentially to enhance mutual understanding and to simplify the overall process of RBH establishment. We support statement that the public authorities should actively participate as a member of the bioeconomy strategy process especially in Ukraine where there are a lot of strong traditions in reference to the regional authority’s engagement. In order to avoid any confrontations in this regard we created RBH in the Lviv region as Council that is functioning under the umbrella of the State Regional Lviv Administration, whose the Head of RBH was the first deputy of the Lviv Governor",
          "title": "Lviv"
      },
      "poland": {
          "text": "Mazovia: The analysis of the current state and potential of the bioeconomy in Mazovia allowed to delineate the trend towards the transition to the bioeconomy and identify the main areas of the economy with the greatest development potential. The catalog of best practices showed and initiated regional discussions on the development of some biotechnology-based production routes that should be promoted.",
          "title": "Mazovia"
      },
      "baviera": {
          "text": "Baviera: Template available in this Phase 1. is a useful tool for the initial step to evaluate the status quo as well as potentials of a regional bioeconomy. The exercise supported us in identifying and structuring important factors such as existent feedstock, products, and infrastructure, as well as key players, new value chains and funding options. This knowledge enabled us to brainstorm innovative capacities with member companies and triggered a profound dialogue with regional policy makers.",
          "text2": "SAT: Working on the SAT stimulated a dialogue with additional bioeconomy-related institutions in the region. However, we experienced difficulties in finding useful data and the questionnaire did not always offer answers that fit to our situation. Despite the fact that not all questions could be answered, information gaps and a lack of information flow between sectors became apparent. The SAT results highlight achievements but also shortcomings of our region, from which fields of action could be derived.",
          "title": "Baviera"
      },
      "paragraph1": "Bioeconomy is a broad and not unique definition. As framework examples, some definitions of bioeconomy are mentioned below to trigger the regional discussions when writing down their bioeconomy definition.",
      "scar1": {
        "text": "{title} The Bioeconomy encompasses the sustainable production of renewable resources from land and water and their subsequent conversion into food, feed, fibers, bio-based products and bio- energy as well as the related public goods products with fewer inputs, less environmental impact and reduced greenhouse gas emissions.",
        "title": "SCAR BIOECONOMY EC: "
      },
      "scar2": "The Bioeconomy includes primary production, such as agriculture, forestry, fisheries and aquaculture, and all industries using/processing biological resources, such as the food/feed and pulp and paper industries and parts of the chemical, biotechnological and energy industries.",
      "tabTitle": "Notes",
      "title": "The definition of Bioeconomy",
      "title2": "Examples from POWER4BIO"
    },
    "opinions": {
      "list": [
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 1"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 2"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 3"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 4"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 5"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 6"
        }
      ],
      "title": "Opinions"
    },
    "paragraph1": "Phase 1 aims to characterize your region and evaluate its bioeconomy potential. You will be able to analyse and evaluate your region against several factors, covering technical, social and economic aspects in the field of Bioeconomy. At the end of this phase, you will complete a SWOT analysis of your region, which will depict  the Bioeconomy potential, opportunities and challenges. It will be the starting point for Phase 2.",
    "paragraph2": "Due to the interdisciplinary factors analysed in the methodology, it is recommended and might be necessary that different professional profiles in the region contribute to all steps and materials requiered, such as analysis, use, reference and integration of the documents, materials and tools made available in the methodology. This engagement  becomes already applicable at this very first stage. Also, the commitment and support from identified stakeholders will be of instrumental importance for the whole methodology. Thus, a wide pool of experts should be involved. Nevertheless, it is highly encouraged that only one person should be responsible of coordinating the response of the region, collecting the necessary information with the support of colleagues from other departments and stakeholders and following up along the different steps depicted in the methodology. This person should also be supported and connected to the Quadruple Helix approach. That is, the involvement of industry, R&D and academia, policy, and civil society. This nominated and agreed person would act as a “promoter” of the regional initiative in using this BSAT. It will maximise the coordination at local level for the sake of a common agreement in the field.",
    "power4BioExperience": "The POWER4BIO experience",
    "realExamples": {
      "cardTitle": "Example of a SWOT analysis",
      "paragraph": "The POWER4BIO regions developed a SWOT in order to assess their regional bioeconomy potential. You can check here the result of the SWOT analysis carried out by one of the POWER4BIO regions",
      "tabTitle": "Real Examples"
    },
    "stepsTitle": "Overview of the steps",
    "subtitle": "Who to involve in this phase?",
    "title": "Phase 1: Assessment of regional bioeconomy potential and status quo"
  }
}
</i18n>

<script>
import Divider from "@/components/Divider";
import { getArrayPiece } from "@/utils/helpers";
export default {
  name: "Phase1",
  components: {
    Step1: () => import("./Step1"),
    Step2: () => import("./Step2"),
    Step3: () => import("./Step3"),
    Step4: () => import("./Step4"),
    Divider
  },
  data() {
    return {
      steps: 1,
      tab: 0,
      step2Tab: 0,
      page: 1,
      faoLink:
        "http://www.fao.org/climate-change/our-work/areas-of-work/bioeconomy/en/",
      scarLink: "https://www.scar-swg-sbgb.eu/the-bioeconomy",
      euCommisionLink: "https://ec.europa.eu/research/bioeconomy/index.cfm",
      swotAnalysisFile: require("@/assets/files/1_1_Example_of_a_swot_analysis.docx")
        .default,
      footerTabs: [
        { tab: "realExamples.tabTitle" },
        { tab: "notes.tabTitle" }
        // { tab: "opinions.title" }
      ],
      phase1: [
        { component: "Step1" },
        { component: "Step2" },
        { component: "Step3" },
        { component: "Step4" }
      ],
      route: this.$route.name
      // file: require("@/assets/files/1_1_2_2.xlsx").default
    };
  },
  computed: {
    opinions() {
      return Object.values(this.$t("opinions.list"));
    },
    currentOpinions() {
      return getArrayPiece(this.opinions, this.page, 3);
    }
  },
  watch: {
    steps(val) {
      if (window) {
        const url = new URL(window.location);
        url.searchParams.set("step", val);
        window.history.pushState({}, "", url);
      }
    }
  },
  mounted() {
    const query = this.$route.query;
    if (query.step) {
      this.steps = Number(query.step);
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/main";

.phase1 {
  margin-top: 20px;
  background-color: $off_white !important;

  .v-window {
    background-color: $off_white;
  }

  .pagination {
    .v-pagination__item {
      background: $inactive_grey;
      min-width: 18px !important;
      height: 20px !important;
      font-size: 10px;
      margin: 0px 3px;
      box-shadow: none !important;
    }
    .v-pagination__navigation {
      background: $inactive_grey;
      min-width: 18px !important;
      width: 18px !important;
      height: 20px !important;
      font-size: 10px;
      box-shadow: none !important;
    }
    .v-pagination__item .v-pagination__item--active {
      box-shadow: none;
    }
    .mdi-chevron-left {
      font-size: 12px;
    }
    .mdi-chevron-right {
      font-size: 12px;
    }
  }

  &__tabs {
    margin-top: 40px;
    margin-bottom: 200px;

    .bsat__paragraph {
      font-size: 14px;
      letter-spacing: 0;
    }
  }

  .v-tab {
    font-family: $font-family;
    text-transform: none;
    letter-spacing: normal;
    justify-items: start;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }

  .steps {
    &__title {
      font-family: $font-family;
      color: $light_green;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.6;
      letter-spacing: 0.2px;
      text-align: left;
    }

    &__content {
      background-color: #ffffff;
      border-radius: 8px;
      border: 1px solid $light_green;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }
    .v-stepper__header {
      background-color: $off_white;
      box-shadow: none;
      // max-width: 50%
    }

    .v-stepper__step--active {
      .v-stepper__step__step {
        align-items: center;
        border-radius: 50%;
        display: inline-flex;
        font-size: 18px;
        font-weight: bold;
        justify-content: center;
        height: 40px;
        min-width: 40px;
        width: 40px;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        background-color: $off_white !important;
        border: 4px solid $light_green !important;
        border-radius: 50%;
        color: $light_green !important;
      }
    }

    .v-stepper__step--inactive {
      .v-stepper__step__step {
        align-items: center;
        border-radius: 50%;
        display: inline-flex;
        font-size: 18px;
        font-weight: bold;
        justify-content: center;
        height: 40px;
        min-width: 40px;
        width: 40px;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        background-color: $off_white !important;
        border: 4px solid $inactive_grey !important;
        border-radius: 50%;
        color: $inactive_grey !important;
      }
    }

    .v-stepper__step__step {
      align-items: center;
      border-radius: 50%;
      display: inline-flex;
      font-size: 18px;
      font-weight: bold;
      justify-content: center;
      height: 40px;
      min-width: 40px;
      width: 40px;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      background-color: $off_white !important;
      border: 4px solid $inactive_grey !important;
      border-radius: 50%;
      color: $inactive_grey !important;
    }
    .v-stepper__step__step:hover {
      cursor: pointer;
    }
  }
}
</style>
